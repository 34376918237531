.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    max-height: 600px;
    width: auto;
  }
  
  .swiper-pagination {
    bottom: 10px !important; 
  }
  .swiper-pagination-bullet {
    background-color: #cf1f1f !important; /* Change the bullet color to pink */
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    color: #cf1f1f !important; /* Change the navigation arrows color to pink */
  }
  